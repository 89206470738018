// App.js
import { Routes, Route } from "react-router-dom";

import AdminLogin from "./components/Adminlogin";
import HotelForm from "./components/HotelForm";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <Routes>
      <Route path="/" element={<AdminLogin />} />
      <Route
        path="/hotelform"
        element={
          <ProtectedRoute>
            <HotelForm />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
