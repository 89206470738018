// //////////////////////////////////////////////
// import Compressor from "compressorjs";
// import { useEffect, useState } from "react";
// import "./../App.css"

// const HotelForm = () => {
//   const [hotelData, setHotelData] = useState({
//     hotel_name: "",
//     hotel_owner_full_name: "", // Hotel Owner Full Name
//     hotel_owner_mobile_number: "",
//     description: "",
//     account_number: "", // Account Number
//     account_holder_name: "", // Account Holder Name
//     bank_name: "", // Bank Name
//     ifsc_code: "", // IFSC Code
//     pan_number: "", // PAN Number
//     gst_number: "", // GST Number
//     aadhar_card_number: "",
//     address: "",
//     location_point: "",
//     city_name: "",
//     state_name: "",
//     country_name: "",
//     phone: "",
//     email: "",
//     hotel_password: "",
//     rating: "",
//     room_count: "",
//     price_range: "",
//     check_in_time: "",
//     check_out_time: "",
//     is_couple_allowed: false,
//     open_24_7: false,
//     deluxe_room_count: "",
//     deluxe_room_price: "",
//     super_deluxe_room_count: "",
//     super_deluxe_room_price: "",
//     standard_room_count: "",
//     standard_room_price: "",
//     suite_room_count: "",
//     suite_room_price: "",
//     cancellation_policy: "",
//     payment_mode: 0,
//   });

//   const [imageFiles, setImageFiles] = useState({
//     deluxe_room: [],
//     super_deluxe_room: [],
//     standard_room: [],
//     suite_room: [],
//     cancel_check_photo: [],
//     gst_photo: [],
//     aadhar_card_photo: [],
//     reception: [],
//     balcony: [],
//   });

//   const [imagePreviews, setImagePreviews] = useState({
//     deluxe_room: [],
//     super_deluxe_room: [],
//     standard_room: [],
//     suite_room: [],
//     reception: [],
//     balcony: [],
//     cancel_check_photo: [],
//     gst_photo: [],
//     aadhar_card_photo: [],
//   });
//   //
//   const [deluxe_room_guest_count, setDeluxe_room_guest] = useState("");
//   const [super_deluxe_room_guest_count, setSuper_deluxe_room_guest] =
//     useState("");
//   const [standard_room_guest_count, setStandard_room_guest] = useState("");
//   const [suite_room_guest_count, setSuite_room_guest] = useState("");

//   const [deluxe_room_description, setDeluxe_room_description] = useState("");
//   const [super_deluxe_room_description, setSuper_Deluxe_room_description] =
//     useState("");
//   const [standard_room_description, setStandard_room_description] =
//     useState("");
//   const [suite_room_description, setSuite_room_description] = useState("");

//   // State to handle password visibility toggle
//   const [showPassword, setShowPassword] = useState(false);

//   const handleTogglePasswordVisibility = () => {
//     setShowPassword(!showPassword); // Toggle the visibility
//   };
//   const [errors, setErrors] = useState({
//     account_number: "",
//     account_holder_name: "",
//     bank_name: "",
//     ifsc_code: "",
//     pan_number: "",
//     gst_number: "",
//     aadhar_card_number: "",
//     hotel_owner_full_name: "",
//     hotel_owner_mobile_number: "",
//     location_point: "",
//     price_range: "",
//   });
//   const [loading, setLoading] = useState(false);
//   const [selectedPolicies, setSelectedPolicies] = useState([]);

//   const [propertyAmenities, setPropertyAmenities] = useState([]); // Selected amenities for Property Amenities
//   const [selectedAmenityProperty, setSelectedAmenityProperty] = useState(""); // Currently selected amenity

//   // List of all available property amenities
//   const availablePropertyAmenities = [
//     "Air Conditioning",
// "Laundry",
// "Newspaper",
// "Parking",
// "Room Service",
// "Smoke Detector",
// "Swimming Pool",
// "Wifi",
// "Lounge",
// "Reception",
// "Bar",
// "Restaurant",
// "Luggage Assistance",
// "Wheelchair Accessibility",
// "Gym/Fitness Centre",
// "CCTV",
// "Fire Extinguishers",
// "First-aid Services",
// "Airport Transfers",
// "Basic Facilities",
// "Elevator/Lift",
// "Housekeeping",
// "Kitchen/Kitchenette",
// "LAN",
// "Power Backup",
// "Genset",
// "Refrigerator",
// "Umbrellas",
// "Washing Machine",
// "Laundromat",
// "EV Charging Station",
// "Driver's Accommodation",
// "General Services",
// "Bellboy Service",
// "Caretaker",
// "Concierge",
// "Multilingual Staff",
// "Luggage Storage",
// "Specially Abled Assistance",
// "Wake-up Call/Service",
// "Butler Services",
// "Doctor on Call",
// "Medical Centre",
// "Pool/Beach Towels",
// "Outdoor Activities and Sports",
// "Beach",
// "Bonfire",
// "Golf",
// "Kayaks",
// "Outdoor Sports (Cricket, Volleyball, Basketball, Tennis, Badminton)",
// "Swings",
// "Rock Climbing",
// "Archery",
// "Hiking",
// "Squash",
// "Snorkelling",
// "Telescope",
// "Water Sports",
// "Water Park",
// "Diving",
// "Canoeing",
// "Skiing",
// "Jungle Safari",
// "Cycling",
// "Common Areas",
// "Balcony/Terrace",
// "Fireplace",
// "Indoor Seating Area",
// "Lawn",
// "Library",
// "Sun Deck",
// "Verandah",
// "Jacuzzi",
// "Prayer Room",
// "Living Room",
// "Outdoor Furniture",
// "Picnic Area",
// "Game Room",
// "Sitout Area",
// "Bonfire Pit",
// "Food and Drink",
// "Barbecue",
// "Café",
// "Coffee Shop",
// "Dining Area",
// "Kid's Menu",
// "Bakery",
// "Breakfast",
// "Food Options Available",
// "Business Center and Conferences",
// "Banquet",
// "Business Center",
// "Conference Room",
// "Photocopying",
// "Fax Service",
// "Printer"
//   ];
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   // Toggle dropdown visibility
//   const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

//   // // Function to add the selected amenity to the list whenever an option is chosen
//   // const handleAmenitySelectProperty = (e) => {
//   //   const selected = e.target.value;
//   //   if (selected && !propertyAmenities.includes(selected)) {
//   //     setPropertyAmenities([...propertyAmenities, selected]);
//   //     setSelectedAmenityProperty(""); // Clear selection after adding
//   //   }
//   // };

//   const handleAmenitySelectProperty = (amenity) => {
//     if (amenity && !propertyAmenities.includes(amenity)) {
//       setPropertyAmenities([...propertyAmenities, amenity]);
//       setSelectedAmenityProperty("");
//     }
//   };

//   // Function to remove an amenity from the list
//   const handleRemoveAmenityProperty = (amenity) => {
//     setPropertyAmenities(propertyAmenities.filter((item) => item !== amenity));
//   };

//   // State for room amenities
//   const [deluxeAmenities, setDeluxeAmenities] = useState([]);
//   const [superDeluxeAmenities, setSuperDeluxeAmenities] = useState([]);
//   const [standardAmenities, setStandardAmenities] = useState([]);
//   const [suiteAmenities, setSuiteAmenities] = useState([]);

//   // List of available amenities
//   const availableAmenities = [
//     "Hairdryer",
//     "Hot & Cold Water",
//     "Toiletries",
//     "TV",
//     "LED",
//     "Air Conditioning",
//     "Iron/Ironing Board",
//     "Mineral Water",
//     "Kettle",
//     "Closet",
//     "Mini Bar",
//     "Telephone",
//     "Local Calls",
//     "Work Desk",
//     "Safe",
//     "Private Bathroom",
//     "Chair",
//     "Popular with Guests",
//     "Interconnected Room",
//     "Heater",
//     "Housekeeping",
//     "In-Room Dining",
//     "Laundry Service",
//     "Room Service",
//     "Smoking Room",
//     "Study Room",
//     "Wifi",
//     "Air Purifier",
//     "Bathroom Phone",
//     "Bathtub",
//     "Bubble Bath",
//     "Dental Kit",
//     "Geyser/Water Heater",
//     "Slippers",
//     "Shower Cap",
//     "Hammam",
//     "Bathrobes",
//     "Western Toilet Seat",
//     "Shower Cubicle",
//     "Weighing Scale",
//     "Shaving Mirror",
//     "Sewing Kit",
//     "Bidet",
//     "Toilet with Grab Rails",
//     "Ensuite Bathroom/Common Bath",
//     "Jetspray",
//     "Blackout Curtains",
//     "Center Table",
//     "Charging Points",
//     "Couch",
//     "Dining Table",
//     "Fireplace",
//     "Mini Fridge",
//     "Sofa",
//     "Pillow Menu",
//     "Hypoallergenic Bedding",
//     "Living Area",
//     "Dining Area",
//     "Seating Area",
//     "Fireplace Guards",
//     "Coffee Machine",
//     "Jacuzzi",
//     "Hot Water Bag",
//   ];

//   const handleInputChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setHotelData({
//       ...hotelData,
//       [name]: type === "checkbox" ? checked : value,
//     });
//   };

//   /////old code
//   // const handleFileChange = (category, files) => {
//   //   const selectedFiles = Array.from(files);
//   //   const imageErrors = {};
//   //   const updatedPreviews = [...imagePreviews[category]];

//   //   selectedFiles.forEach((file) => {
//   //     if (!file.type.startsWith("image/")) {
//   //       imageErrors[category] = "Only image files are allowed.";
//   //     }
//   //     if (file.size > 5 * 1024 * 1024) {
//   //       imageErrors[category] = "File size should not exceed 5 MB.";
//   //     }
//   //     const previewURL = URL.createObjectURL(file);
//   //     updatedPreviews.push(previewURL);
//   //   });

//   //   setImageFiles({
//   //     ...imageFiles,
//   //     [category]: selectedFiles,
//   //   });

//   //   setImagePreviews({
//   //     ...imagePreviews,
//   //     [category]: updatedPreviews,
//   //   });

//   //   setErrors((prevErrors) => ({
//   //     ...prevErrors,
//   //     ...imageErrors,
//   //   }));
//   // };

//   // Handling file change with compression
//   const handleFileChange = (category, files) => {
//     const selectedFiles = Array.from(files);
//     const imageErrors = {};
//     const updatedPreviews = [...(imagePreviews[category] || [])];
//     const updatedCompressedFiles = [...(imageFiles[category] || [])]; // Array to store compressed files

//     selectedFiles.forEach((file) => {
//       if (!file.type.startsWith("image/")) {
//         imageErrors[category] = "Only image files are allowed.";
//       }
//       if (file.size > 5 * 1024 * 1024) {
//         imageErrors[category] = "File size should not exceed 5 MB.";
//       }

//       // Compress the image before pushing it into previews and imageFiles state
//       new Compressor(file, {
//         quality: 0.9, // Set the quality (0-1, 1 being the highest quality)
//         maxWidth: 800, // Max width of the image
//         maxHeight: 800, // Max height of the image
//         success(result) {
//           const previewURL = URL.createObjectURL(result); // Generate URL for the compressed image
//           updatedPreviews.push(previewURL); // Add the preview to the updated list

//           // Push the compressed file into the array
//           updatedCompressedFiles.push(result);
//         },
//         error(err) {
//           console.error("Error during image compression:", err);
//         },
//       });
//     });

//     // Update state for image files, previews, and errors
//     setImageFiles((prevFiles) => ({
//       ...prevFiles,
//       [category]: updatedCompressedFiles, // Update the image files with compressed files
//     }));

//     setImagePreviews((prevPreviews) => ({
//       ...prevPreviews,
//       [category]: updatedPreviews,
//     }));

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       ...imageErrors,
//     }));
//   };

//   const handleRemoveImage = (category, index) => {
//     const updatedFiles = [...imageFiles[category]];
//     const updatedPreviews = [...imagePreviews[category]];

//     updatedFiles.splice(index, 1);
//     updatedPreviews.splice(index, 1);

//     setImageFiles({
//       ...imageFiles,
//       [category]: updatedFiles,
//     });

//     setImagePreviews({
//       ...imagePreviews,
//       [category]: updatedPreviews,
//     });
//   };

//   const validateForm = () => {
//     const newErrors = {};

//     // Check required fields
//     const requiredFields = [
//       "hotel_name",
//       "account_number",
//       "description",
//       "address",
//       "city_name",
//       "state_name",
//       "country_name",
//       "phone",
//       "email",
//       "rating",
//       "check_in_time",
//       "check_out_time",
//     ];

//     requiredFields.forEach((key) => {
//       if (hotelData[key] === "" || hotelData[key] === null) {
//         newErrors[key] = "This field is required";
//       }
//     });

//     // Check if at least one room type is provided
//     const roomTypes = [
//       "deluxe_room",
//       "super_deluxe_room",
//       "standard_room",
//       "suite_room",
//     ];

//     const hasRoomType = roomTypes.some((roomType) => {
//       return (
//         hotelData[`${roomType}_count`] > 0 && hotelData[`${roomType}_price`] > 0
//       );
//     });

//     if (!hasRoomType) {
//       newErrors.room_types = "At least one room type must be added.";
//     }

//     // Check if images are uploaded for each room type
//     roomTypes.forEach((roomType) => {
//       if (
//         hotelData[`${roomType}_count`] > 0 &&
//         imageFiles[roomType].length === 0
//       ) {
//         newErrors[roomType] =
//           "At least one image is required for this room type.";
//       }
//     });

//     return newErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Log all data before submission (for debugging)
//     console.log("Hotel Data:", hotelData);
//     console.log("Image Files:", imageFiles);
//     console.log("Image Previews:", imagePreviews);
//     console.log("Selected Policies:", selectedPolicies);
//     console.log("Deluxe Amenities:", deluxeAmenities);
//     console.log("Super Deluxe Amenities:", superDeluxeAmenities);
//     console.log("Standard Amenities:", standardAmenities);
//     console.log("Suite Amenities:", suiteAmenities);
//     console.log("Property Amenities", propertyAmenities);
//     console.log("deluxe_room_guest_count", deluxe_room_guest_count);
//     console.log("super_deluxe_room_guest_count", super_deluxe_room_guest_count);
//     console.log("standard_room_guest_count", standard_room_guest_count);
//     console.log("suite_room_guest_count", suite_room_guest_count);
//     console.log("Deluxe Room Description:", deluxe_room_description);
//     console.log(
//       "Super Deluxe Room Description:",
//       super_deluxe_room_description
//     );
//     console.log("Standard Room Description:", standard_room_description);
//     console.log("Suite Room Description:", suite_room_description);

//     // Validate form before submitting
//     const validationErrors = validateForm();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return; // Stop further execution if validation fails
//     }

//     setLoading(true);

//     // Create FormData object
//     const formData = new FormData();

//     // Append hotel data fields
//     Object.keys(hotelData).forEach((key) => {
//       formData.append(key, hotelData[key]);
//     });

//     // Append image files by category
//     Object.keys(imageFiles).forEach((category) => {
//       Array.from(imageFiles[category]).forEach((file) => {
//         formData.append(category + "[]", file);
//       });
//     });

//     // Append room amenities as JSON strings
//     // The amenities for each room type are stored as arrays or objects and need to be serialized into JSON strings
//     // before appending to the FormData object for proper handling on the server.
//     formData.append("deluxe_room_amenities", JSON.stringify(deluxeAmenities)); // Deluxe room amenities
//     formData.append(
//       "super_deluxe_room_amenities",
//       JSON.stringify(superDeluxeAmenities)
//     ); // Super deluxe room amenities
//     formData.append(
//       "standard_room_amenities",
//       JSON.stringify(standardAmenities)
//     ); // Standard room amenities
//     formData.append("suite_room_amenities", JSON.stringify(suiteAmenities)); // Suite room amenities

//     // Append selected policies as JSON string
//     // Selected policies and property-level amenities are appended as JSON strings for structured data transfer.
//     formData.append("selected_policies", JSON.stringify(selectedPolicies)); // Hotel policies selected by the user
//     formData.append("property_amenities", JSON.stringify(propertyAmenities)); // General property amenities

//     // Append room guest counts
//     // Guest count for each room type is appended as individual fields to track the maximum number of guests allowed per room.
//     formData.append("deluxe_room_guest_count", deluxe_room_guest_count); // Maximum guests for deluxe room
//     formData.append(
//       "super_deluxe_room_guest_count",
//       super_deluxe_room_guest_count
//     ); // Maximum guests for super deluxe room
//     formData.append("standard_room_guest_count", standard_room_guest_count); // Maximum guests for standard room
//     formData.append("suite_room_guest_count", suite_room_guest_count); // Maximum guests for suite room

//     // Append room descriptions
//     // Descriptions for each room type are appended to provide detailed information about the room to the server.
//     formData.append("deluxe_room_description", deluxe_room_description); // Description for deluxe room
//     formData.append(
//       "super_deluxe_room_description",
//       super_deluxe_room_description
//     ); // Description for super deluxe room
//     formData.append("standard_room_description", standard_room_description); // Description for standard room
//     formData.append("suite_room_description", suite_room_description); // Description for suite room

//     try {
//       const response = await fetch(
//         "https://tabtrips.com/hotel_api/single_api.php",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );

//       // Check if the response was successful
//       if (!response.ok) {
//         console.error("Response error status:", response.status);
//         throw new Error(`Failed to add hotel: ${response.statusText}`);
//       } else {
//         alert("Data added successfully into database");
//       }

//       // Parse the response as JSON
//       const data = await response.json();
//       console.log("Parsed API Response:", data);
//     } catch (error) {
//       console.error("Error in adding hotel:", error);
//       alert("An error occurred while adding the hotel. Please try again.");
//     } finally {
//       setLoading(false); // Reset loading state
//       console.log("Hotel addition process complete.");
//     }
//   };

//   // Handle policy selection
//   function handlePolicySelection(policy) {
//     if (!selectedPolicies.includes(policy) && policy !== "Select Policy") {
//       setSelectedPolicies([...selectedPolicies, policy]);
//     }
//   }

//   // Handle removing a policy
//   function handleRemovePolicy(index) {
//     const updatedPolicies = selectedPolicies.filter((_, i) => i !== index);
//     setSelectedPolicies(updatedPolicies);
//   }

//   // Handle amenity selection for room types
//   const handleSelectAmenity = (roomType, amenity) => {
//     if (roomType === "deluxe" && !deluxeAmenities.includes(amenity)) {
//       setDeluxeAmenities([...deluxeAmenities, amenity]);
//     } else if (
//       roomType === "superDeluxe" &&
//       !superDeluxeAmenities.includes(amenity)
//     ) {
//       setSuperDeluxeAmenities([...superDeluxeAmenities, amenity]);
//     } else if (
//       roomType === "standard" &&
//       !standardAmenities.includes(amenity)
//     ) {
//       setStandardAmenities([...standardAmenities, amenity]);
//     } else if (roomType === "suite" && !suiteAmenities.includes(amenity)) {
//       setSuiteAmenities([...suiteAmenities, amenity]);
//     }
//   };

//   // Handle amenity removal for room types
//   const handleRemoveAmenity = (roomType, amenity) => {
//     if (roomType === "deluxe") {
//       setDeluxeAmenities(deluxeAmenities.filter((a) => a !== amenity));
//     } else if (roomType === "superDeluxe") {
//       setSuperDeluxeAmenities(
//         superDeluxeAmenities.filter((a) => a !== amenity)
//       );
//     } else if (roomType === "standard") {
//       setStandardAmenities(standardAmenities.filter((a) => a !== amenity));
//     } else if (roomType === "suite") {
//       setSuiteAmenities(suiteAmenities.filter((a) => a !== amenity));
//     }
//   };
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (!event.target.closest(".property-amenities-section")) {
//         setIsDropdownOpen(false);
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, []);

//   return (
//     <div className="container">
//       <h1 className="welcome-title">Welcome to TabTrips: Add Your New Hotel</h1>
//       <form onSubmit={handleSubmit}>
//         {/* Hotel Details Form */}
//         <div className="form-group">
//           <label>Hotel Name:</label>
//           <input
//             type="text"
//             name="hotel_name"
//             value={hotelData.hotel_name}
//             onChange={handleInputChange}
//             className={errors.hotel_name ? "input-error" : ""}
//             required
//             placeholder="Enter hotel name"
//           />
//           {errors.hotel_name && (
//             <span className="error">{errors.hotel_name}</span>
//           )}
//         </div>
//         {/* Hotel Owner Full Name */}
//         <div className="form-group">
//           <label>Hotel Owner Full Name:</label>
//           <input
//             type="text"
//             name="hotel_owner_full_name"
//             value={hotelData.hotel_owner_full_name}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 hotel_owner_full_name: e.target.value,
//               }))
//             }
//             className={errors.hotel_owner_full_name ? "input-error" : ""}
//             required
//             placeholder="Enter hotel owner full name"
//           />
//           {errors.hotel_owner_full_name && (
//             <span className="error">{errors.hotel_owner_full_name}</span>
//           )}
//         </div>

//         {/* Hotel Owner Number */}
//         <div className="form-group">
//           <label>Hotel Owner Number:</label>
//           <input
//             type="text"
//             name="hotel_owner_mobile_number"
//             value={hotelData.hotel_owner_mobile_number}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 hotel_owner_mobile_number: e.target.value,
//               }))
//             }
//             className={errors.hotel_owner_number ? "input-error" : ""}
//             required
//             placeholder="Enter hotel owner number"
//           />
//           {errors.hotel_owner_number && (
//             <span className="error">{errors.hotel_owner_number}</span>
//           )}
//         </div>

//         <div className="form-group">
//           <label>Account Number:</label>
//           <input
//             type="text"
//             name="account_number"
//             value={hotelData.account_number}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 account_number: e.target.value,
//               }))
//             }
//             className={errors.account_number ? "input-error" : ""}
//             required
//             placeholder="Enter account number"
//           />
//           {errors.account_number && (
//             <span className="error">{errors.account_number}</span>
//           )}
//         </div>

//         {/* Account Holder Name */}
//         <div className="form-group">
//           <label>Account Holder Name:</label>
//           <input
//             type="text"
//             name="account_holder_name"
//             value={hotelData.account_holder_name}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 account_holder_name: e.target.value,
//               }))
//             }
//             className={errors.account_holder_name ? "input-error" : ""}
//             required
//             placeholder="Enter account holder name"
//           />
//           {errors.account_holder_name && (
//             <span className="error">{errors.account_holder_name}</span>
//           )}
//         </div>

//         {/* Bank Name */}
//         <div className="form-group">
//           <label>Bank Name:</label>
//           <input
//             type="text"
//             name="bank_name"
//             value={hotelData.bank_name}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 bank_name: e.target.value,
//               }))
//             }
//             className={errors.bank_name ? "input-error" : ""}
//             required
//             placeholder="Enter bank name"
//           />
//           {errors.bank_name && (
//             <span className="error">{errors.bank_name}</span>
//           )}
//         </div>

//         {/* IFSC Code */}
//         <div className="form-group">
//           <label>IFSC Code:</label>
//           <input
//             type="text"
//             name="ifsc_code"
//             value={hotelData.ifsc_code}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 ifsc_code: e.target.value,
//               }))
//             }
//             className={errors.ifsc_code ? "input-error" : ""}
//             required
//             placeholder="Enter IFSC code"
//           />
//           {errors.ifsc_code && (
//             <span className="error">{errors.ifsc_code}</span>
//           )}
//         </div>

//         {/* PAN Number */}
//         <div className="form-group">
//           <label>PAN Number:</label>
//           <input
//             type="text"
//             name="pan_number"
//             value={hotelData.pan_number}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 pan_number: e.target.value,
//               }))
//             }
//             className={errors.pan_number ? "input-error" : ""}
//             required
//             placeholder="Enter PAN number"
//           />
//           {errors.pan_number && (
//             <span className="error">{errors.pan_number}</span>
//           )}
//         </div>

//         {/* GST Number */}
//         <div className="form-group">
//           <label>GST Number:</label>
//           <input
//             type="text"
//             name="gst_number"
//             value={hotelData.gst_number}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 gst_number: e.target.value,
//               }))
//             }
//             className={errors.gst_number ? "input-error" : ""}
//             required
//             placeholder="Enter GST number"
//           />
//           {errors.gst_number && (
//             <span className="error">{errors.gst_number}</span>
//           )}
//         </div>

//         {/* Aadhar Card Number */}
//         <div className="form-group">
//           <label>Aadhar Card Number:</label>
//           <input
//             type="text"
//             name="aadhar_card_number"
//             value={hotelData.aadhar_card_number}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 aadhar_card_number: e.target.value,
//               }))
//             }
//             className={errors.aadhar_card_number ? "input-error" : ""}
//             required
//             placeholder="Enter Aadhar card number"
//           />
//           {errors.aadhar_card_number && (
//             <span className="error">{errors.aadhar_card_number}</span>
//           )}
//         </div>

//         {/* Description */}
//         <div className="form-group">
//           <label>Description:</label>
//           <textarea
//             name="description"
//             value={hotelData.description}
//             onChange={handleInputChange}
//             className={errors.description ? "input-error" : ""}
//             required
//             placeholder="Briefly describe your hotel..."
//           ></textarea>
//           {errors.description && (
//             <span className="error">{errors.description}</span>
//           )}
//         </div>

//         {/* Address */}
//         <div className="form-group">
//           <label>Address:</label>
//           <input
//             type="text"
//             name="address"
//             value={hotelData.address}
//             onChange={handleInputChange}
//             className={errors.address ? "input-error" : ""}
//             required
//             placeholder="Enter full address"
//           />
//           {errors.address && <span className="error">{errors.address}</span>}
//         </div>
//         {/* Location Point */}
//         <div className="form-group">
//           <label>Location Point:</label>
//           <input
//             type="text"
//             name="location_point"
//             value={hotelData.location_point}
//             onChange={(e) =>
//               setHotelData((prevData) => ({
//                 ...prevData,
//                 location_point: e.target.value,
//               }))
//             }
//             className={errors.location_point ? "input-error" : ""}
//             required
//             placeholder="Enter location point (latitude, longitude)"
//           />
//           {errors.location_point && (
//             <span className="error">{errors.location_point}</span>
//           )}
//         </div>

//         {/* Country */}
//         <div className="form-group">
//           <label>Country:</label>
//           <input
//             type="text"
//             name="country_name"
//             value={hotelData.country_name}
//             onChange={handleInputChange}
//             className={errors.country_name ? "input-error" : ""}
//             required
//             placeholder="Enter country name"
//           />
//           {errors.country_name && (
//             <span className="error">{errors.country_name}</span>
//           )}
//         </div>

//         {/* State */}
//         <div className="form-group">
//           <label>State:</label>
//           <input
//             type="text"
//             name="state_name"
//             value={hotelData.state_name}
//             onChange={handleInputChange}
//             className={errors.state_name ? "input-error" : ""}
//             required
//             placeholder="Enter state name"
//           />
//           {errors.state_name && (
//             <span className="error">{errors.state_name}</span>
//           )}
//         </div>

//         {/* City */}
//         <div className="form-group">
//           <label>City:</label>
//           <input
//             type="text"
//             name="city_name"
//             value={hotelData.city_name}
//             onChange={handleInputChange}
//             className={errors.city_name ? "input-error" : ""}
//             required
//             placeholder="Enter city name"
//           />
//           {errors.city_name && (
//             <span className="error">{errors.city_name}</span>
//           )}
//         </div>

//         {/* Phone */}
//         <div className="form-group">
//           <label>Phone:</label>
//           <input
//             type="text"
//             name="phone"
//             value={hotelData.phone}
//             onChange={handleInputChange}
//             className={errors.phone ? "input-error" : ""}
//             required
//             placeholder="Enter phone number"
//           />
//           {errors.phone && <span className="error">{errors.phone}</span>}
//         </div>

//         {/* Email */}
//         <div className="form-group">
//           <label>Email:</label>
//           <input
//             type="email"
//             name="email"
//             value={hotelData.email}
//             onChange={handleInputChange}
//             className={errors.email ? "input-error" : ""}
//             required
//             placeholder="Enter email address"
//           />
//           {errors.email && <span className="error">{errors.email}</span>}
//         </div>

//         {/* Password */}
//         <div className="form-group">
//           <label>Password:</label>
//           <div className="password-container">
//             <input
//               type={showPassword ? "text" : "password"}
//               name="hotel_password"
//               value={hotelData.hotel_password}
//               onChange={handleInputChange}
//               className={errors.hotel_password ? "input-error" : ""}
//               required
//               placeholder="Enter password"
//             />
//             <button
//               type="button"
//               onClick={handleTogglePasswordVisibility}
//               className="password-toggle-btn"
//             >
//               {showPassword ? "Hide" : "Show"}
//             </button>
//           </div>
//           {errors.hotel_password && (
//             <span className="error">{errors.hotel_password}</span>
//           )}
//         </div>

//         {/* Rating */}
//         <div className="form-group">
//           <label>Rating (1-5):</label>
//           <input
//             type="number"
//             name="rating"
//             min="1"
//             max="5"
//             value={hotelData.rating}
//             required
//             onChange={handleInputChange}
//             className={errors.rating ? "input-error" : ""}
//             placeholder="Enter rating"
//           />
//           {errors.rating && <span className="error">{errors.rating}</span>}
//         </div>

//         {/* Room Count */}
//         <div className="form-group">
//           <label>Hotel Total Room Count:</label>
//           <input
//             type="number"
//             name="room_count"
//             min="1"
//             value={hotelData.room_count}
//             required
//             onChange={handleInputChange}
//             className={errors.room_count ? "input-error" : ""}
//             placeholder="Enter number of rooms"
//           />
//           {errors.room_count && (
//             <span className="error">{errors.room_count}</span>
//           )}
//         </div>

//         {/* Price Range */}
//         <div className="form-group">
//           <label>Price Range:</label>
//           <input
//             type="text"
//             name="price_range"
//             value={hotelData.price_range}
//             onChange={handleInputChange}
//             className={errors.price_range ? "input-error" : ""}
//             placeholder="Enter price range"
//           />
//           {errors.price_range && (
//             <span className="error">{errors.price_range}</span>
//           )}
//         </div>

//         {/* Check-in Time */}
//         <div className="form-group">
//           <label>Check-in Time:</label>
//           <input
//             type="time"
//             name="check_in_time"
//             value={hotelData.check_in_time}
//             step="1"
//             onChange={handleInputChange}
//             required
//             className={errors.check_in_time ? "input-error" : ""}
//           />
//           {errors.check_in_time && (
//             <span className="error">{errors.check_in_time}</span>
//           )}
//         </div>

//         {/* Check-out Time */}
//         <div className="form-group">
//           <label>Check-out Time:</label>
//           <input
//             type="time"
//             name="check_out_time"
//             value={hotelData.check_out_time}
//             required
//             step="1"
//             onChange={handleInputChange}
//             className={errors.check_out_time ? "input-error" : ""}
//           />
//           {errors.check_out_time && (
//             <span className="error">{errors.check_out_time}</span>
//           )}
//         </div>

//         {/* Couples Allowed */}
//         <div className="form-group">
//           <label>
//             <input
//               type="checkbox"
//               name="is_couple_allowed"
//               checked={hotelData.is_couple_allowed}
//               onChange={handleInputChange}
//             />
//             Couples Allowed
//           </label>
//         </div>

//         {/* Open 24/7 */}
//         <div className=" form-group">
//           <label>
//             <input
//               type="checkbox"
//               name="open_24_7"
//               checked={hotelData.open_24_7}
//               onChange={handleInputChange}
//             />
//             Open 24/7
//           </label>
//         </div>

//         {/* Payment mode */}
//         <div className=" form-group">
//           <label>
//             <input
//               type="checkbox"
//               name="payment_mode"
//               checked={hotelData.payment_mode}
//               onChange={handleInputChange}
//             />
//             PaymentMode/Online/Offline
//           </label>
//         </div>

//         <div>
//           {/* Room Types */}
//           <h2>Room Types:</h2>

//           {/* Deluxe Room */}
//           <div className="form-group">
//             <label>Deluxe Room:</label>
//             <input
//               type="number"
//               name="deluxe_room_count"
//               min="1"
//               value={hotelData.deluxe_room_count}
//               onChange={handleInputChange}
//               className={errors.deluxe_room_count ? "input-error" : ""}
//               placeholder="Enter number of deluxe rooms"
//             />
//             <input
//               type="number"
//               name="deluxe_room_price"
//               min="1"
//               value={hotelData.deluxe_room_price}
//               onChange={handleInputChange}
//               className={errors.deluxe_room_price ? "input-error" : ""}
//               placeholder="Enter price of deluxe room"
//             />
//             {/* Maximum Guests for Deluxe Room */}
//             <input
//               type="number"
//               name="deluxe_room_guest_count"
//               min="1"
//               value={deluxe_room_guest_count}
//               onChange={(e) => setDeluxe_room_guest(e.target.value)}
//               placeholder="Enter maximum guests for deluxe room"
//             />
//             {/* deluxe_room_description */}
//             <input
//               type="text"
//               name="deluxe_room_description"
//               value={deluxe_room_description}
//               onChange={(e) => setDeluxe_room_description(e.target.value)}
//               placeholder="Enter description for deluxe room"
//             />

//             <select
//               onChange={(e) => handleSelectAmenity("deluxe", e.target.value)}
//               className="w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 mt-2"
//               defaultValue="Select Amenity"
//             >
//               <option disabled>Select Amenity</option>
//               {availableAmenities.map((amenity, index) => (
//                 <option key={index} value={amenity}>
//                   {amenity}
//                 </option>
//               ))}
//             </select>

//             <div className="mt-2 p-4 border border-gray-300 rounded-md bg-gray-50">
//               {deluxeAmenities.map((amenity, index) => (
//                 <div
//                   key={index}
//                   className="inline-flex items-center bg-orange-200 text-orange-800 text-sm font-semibold mr-2 mb-2 px-3 py-1 rounded-full shadow-sm"
//                 >
//                   {amenity}
//                   <button
//                     onClick={() => handleRemoveAmenity("deluxe", amenity)}
//                     className="ml-2 text-gray-600 hover:text-red-500 focus:outline-none"
//                   >
//                     &times;
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Super Deluxe Room */}
//           <div className="form-group">
//             <label>Super Deluxe Room:</label>
//             <input
//               type="number"
//               name="super_deluxe_room_count"
//               min="1"
//               value={hotelData.super_deluxe_room_count}
//               onChange={handleInputChange}
//               className={errors.super_deluxe_room_count ? "input-error" : ""}
//               placeholder="Enter number of super deluxe rooms"
//             />
//             <input
//               type="number"
//               name="super_deluxe_room_price"
//               min="1"
//               value={hotelData.super_deluxe_room_price}
//               onChange={handleInputChange}
//               className={errors.super_deluxe_room_price ? "input-error" : ""}
//               placeholder="Enter price of super deluxe room"
//             />

//             {/* Maximum Guests for Super Deluxe Room */}
//             <input
//               type="number"
//               name="super_deluxe_room_guest_count"
//               min="1"
//               value={super_deluxe_room_guest_count}
//               onChange={(e) => setSuper_deluxe_room_guest(e.target.value)}
//               placeholder="Enter maximum guests for super deluxe room"
//             />
//             {/* Input for Super Deluxe Room Description */}
//             <input
//               type="text"
//               name="super_deluxe_room_description"
//               value={super_deluxe_room_description}
//               onChange={(e) => setSuper_Deluxe_room_description(e.target.value)}
//               placeholder="Enter description for super deluxe room"
//             />
//             <select
//               onChange={(e) =>
//                 handleSelectAmenity("superDeluxe", e.target.value)
//               }
//               className="w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 mt-2"
//               defaultValue="Select Amenity"
//             >
//               <option disabled>Select Amenity</option>
//               {availableAmenities.map((amenity, index) => (
//                 <option key={index} value={amenity}>
//                   {amenity}
//                 </option>
//               ))}
//             </select>

//             <div className="mt-2 p-4 border border-gray-300 rounded-md bg-gray-50">
//               {superDeluxeAmenities.map((amenity, index) => (
//                 <div
//                   key={index}
//                   className="inline-flex items-center bg-orange-200 text-orange-800 text-sm font-semibold mr-2 mb-2 px-3 py-1 rounded-full shadow-sm"
//                 >
//                   {amenity}
//                   <button
//                     onClick={() => handleRemoveAmenity("superDeluxe", amenity)}
//                     className="ml-2 text-gray-600 hover:text-red-500 focus:outline-none"
//                   >
//                     &times;
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Standard Room */}
//           <div className="form-group">
//             <label>Standard Room:</label>
//             <input
//               type="number"
//               name="standard_room_count"
//               min="1"
//               value={hotelData.standard_room_count}
//               onChange={handleInputChange}
//               className={errors.standard_room_count ? "input-error" : ""}
//               placeholder="Enter number of standard rooms"
//             />
//             <input
//               type="number"
//               name="standard_room_price"
//               min="1"
//               value={hotelData.standard_room_price}
//               onChange={handleInputChange}
//               className={errors.standard_room_price ? "input-error" : ""}
//               placeholder="Enter price of standard room"
//             />
//             {/* Maximum Guests for Standard Room */}
//             <input
//               type="number"
//               name="standard_room_guest_count"
//               min="1"
//               value={standard_room_guest_count}
//               onChange={(e) => setStandard_room_guest(e.target.value)}
//               placeholder="Enter maximum guests for standard room"
//             />
//             {/* Input for Standard Room Description */}
//             <input
//               type="text"
//               name="standard_room_description"
//               value={standard_room_description}
//               onChange={(e) => setStandard_room_description(e.target.value)}
//               placeholder="Enter description for standard room"
//             />

//             <select
//               onChange={(e) => handleSelectAmenity("standard", e.target.value)}
//               className="w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 mt-2"
//               defaultValue="Select Amenity"
//             >
//               <option disabled>Select Amenity</option>
//               {availableAmenities.map((amenity, index) => (
//                 <option key={index} value={amenity}>
//                   {amenity}
//                 </option>
//               ))}
//             </select>

//             <div className="mt-2 p-4 border border-gray-300 rounded-md bg-gray-50">
//               {standardAmenities.map((amenity, index) => (
//                 <div
//                   key={index}
//                   className="inline-flex items-center bg-orange-200 text-orange-800 text-sm font-semibold mr-2 mb-2 px-3 py-1 rounded-full shadow-sm"
//                 >
//                   {amenity}
//                   <button
//                     onClick={() => handleRemoveAmenity("standard", amenity)}
//                     className="ml-2 text-gray-600 hover:text-red-500 focus:outline-none"
//                   >
//                     &times;
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Suite Room */}
//           <div className="form-group">
//             <label>Suite Room:</label>
//             <input
//               type="number"
//               name="suite_room_count"
//               min="1"
//               value={hotelData.suite_room_count}
//               onChange={handleInputChange}
//               className={errors.suite_room_count ? "input-error" : ""}
//               placeholder="Enter number of suite rooms"
//             />
//             <input
//               type="number"
//               name="suite_room_price"
//               min="1"
//               value={hotelData.suite_room_price}
//               onChange={handleInputChange}
//               className={errors.suite_room_price ? "input-error" : ""}
//               placeholder="Enter price of suite room"
//             />

//             {/* Maximum Guests for Suite Room */}
//             <input
//               type="number"
//               name="suite_room_guest_count"
//               min="1"
//               value={suite_room_guest_count}
//               onChange={(e) => setSuite_room_guest(e.target.value)}
//               placeholder="Enter maximum guests for suite room"
//             />

//             {/* Input for Suite Room Description */}
//             <input
//               type="text"
//               name="suite_room_description"
//               value={suite_room_description}
//               onChange={(e) => setSuite_room_description(e.target.value)}
//               placeholder="Enter description for suite room"
//             />
//             <select
//               onChange={(e) => handleSelectAmenity("suite", e.target.value)}
//               className="w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 mt-2"
//               defaultValue="Select Amenity"
//             >
//               <option disabled>Select Amenity</option>
//               {availableAmenities.map((amenity, index) => (
//                 <option key={index} value={amenity}>
//                   {amenity}
//                 </option>
//               ))}
//             </select>

//             <div className="mt-2 p-4 border border-gray-300 rounded-md bg-gray-50">
//               {suiteAmenities.map((amenity, index) => (
//                 <div
//                   key={index}
//                   className="inline-flex items-center bg-orange-200 text-orange-800 text-sm font-semibold mr-2 mb-2 px-3 py-1 rounded-full shadow-sm"
//                 >
//                   {amenity}
//                   <button
//                     onClick={() => handleRemoveAmenity("suite", amenity)}
//                     className="ml-2 text-gray-600 hover:text-red-500 focus:outline-none"
//                   >
//                     &times;
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>

//         {/* Image Upload Section */}
//         <h2>Upload Hotel Images:</h2>
//         {[
//           "deluxe_room",
//           "super_deluxe_room",
//           "standard_room",
//           "suite_room",
//           "reception",
//           "balcony",
//           "cancel_check_photo",
//           "gst_photo",
//           "aadhar_card_photo",
//         ].map((category) => (
//           <div key={category} className="form-group">
//             {/* <label>{category.replace(/_/g, " ").toUpperCase()}:</label> */}
//             <label>
//               {category.toLowerCase() === "balcony"
//                 ? "HOTEL IMAGES"
//                 : category.replace(/_/g, " ").toUpperCase()}
//             </label>

//             <input
//               type="file"
//               accept="image/*"
//               multiple
//               onChange={(e) => handleFileChange(category, e.target.files)}
//             />
//             {errors[category] && (
//               <span className="error">{errors[category]}</span>
//             )}
//             <div className="image-previews">
//               {imagePreviews[category].map((preview, index) => (
//                 <div key={index} className="image-preview">
//                   <img src={preview} alt={"Preview ${category}${index}"} />
//                   <button
//                     className="remove-button"
//                     onClick={() => handleRemoveImage(category, index)}
//                   >
//                     X
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>
//         ))}

//         {errors.room_types && (
//           <span className="error">{errors.room_types}</span>
//         )}

//         <h3 className="text-xl font-semibold text-gray-700 mb-4">
//           Property Policies
//         </h3>
//         <div className="policy-dropdown mb-6">
//           <select
//             onChange={(e) => handlePolicySelection(e.target.value)}
//             required
//             className="w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400"
//             defaultValue="Select Policy"
//           >
//             <option disabled>Select Policy</option>
//             <optgroup label="Property Rules">
//               <option>Unmarried Couples Allowed</option>
//               <option>Guests Below 18 Allowed</option>
//             </optgroup>
//             <optgroup label="Guest Profile">
//               <option>Male Groups Allowed</option>
//             </optgroup>
//             <optgroup label="Acceptable Identity Proofs">
//               <option>
//                 Required IDs: Passport, Aadhar, Driving License, Government ID
//               </option>
//               <option>Local IDs Allowed</option>
//             </optgroup>
//             <optgroup label="Property Restrictions">
//               <option>Smoking Allowed</option>
//               <option>Private Parties/Events Allowed</option>
//               <option>Outside Visitors Allowed</option>
//               <option>Wheelchair Accessible</option>
//             </optgroup>
//             <optgroup label="Pet Policy">
//               <option>Pets Allowed</option>
//               <option>Current Pets on Property</option>
//             </optgroup>
//             <optgroup label="Check-in and Check-out Policies">
//               <option>24-Hour Check-in</option>
//             </optgroup>
//             <optgroup label="Extra Bed Policies">
//               <option>Extra Beds for Adults</option>
//               <option>Type of Extra Bed (e.g., mattress)</option>
//               <option>Extra Mattress Charges (₹800)</option>
//               <option>Extra Beds for Kids</option>
//             </optgroup>
//           </select>

//           <div className="policy-textbox mt-4 p-4 border border-gray-300 rounded-md bg-gray-50">
//             {selectedPolicies.map((policy, index) => (
//               <div
//                 key={index}
//                 className="policy-tile inline-flex items-center bg-orange-200 text-orange-800 text-sm font-semibold mr-2 mb-2 px-3 py-1 rounded-full shadow-sm"
//               >
//                 {policy}
//                 <button
//                   onClick={() => handleRemovePolicy(index)}
//                   className="ml-2 text-gray-600 hover:text-red-500 focus:outline-none"
//                 >
//                   &times;
//                 </button>
//               </div>
//             ))}
//           </div>
//         </div>
//         {/* Other form fields can go here */}

//         {/* Property Amenities Section */}
//         <div className="property-amenities-section mt-4">
//           <h2 className="text-lg font-semibold text-gray-700">
//             Property Amenities
//           </h2>
//           <div className="relative mt-2">
//             <label
//               htmlFor="property-amenities-dropdown"
//               className="block text-gray-700 mb-1"
//             >
//               Select Amenities:
//             </label>

//             {/* Custom dropdown button */}
//             <button
//               type="button"
//               onClick={toggleDropdown}
//               className="border border-gray-300 rounded px-3 py-2 w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-orange-500"
//             >
//               {selectedAmenityProperty.length > 0
//                 ? `Selected Amenities: ${selectedAmenityProperty.length}`
//                 : "-- Select Amenities --"}
//             </button>

//             {/* Dropdown menu for amenities with checkboxes */}
//             {isDropdownOpen && (
//               <div className="absolute mt-2 w-full border border-gray-300 bg-white shadow-lg rounded-md z-10">
//                 {availablePropertyAmenities?.map((amenity, index) => (
//                   <div key={index} className="flex items-center px-3 py-2">
//                     <input
//                       type="checkbox"
//                       id={`amenity-${index}`}
//                       value={amenity}
//                       checked={propertyAmenities.includes(amenity)}
//                       onChange={() => handleAmenitySelectProperty(amenity)}
//                       className="mr-2"
//                     />
//                     <label
//                       htmlFor={`amenity-${index}`}
//                       className="text-gray-700"
//                     >
//                       {amenity}
//                     </label>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>

//           {/* Display selected amenities as tags with 'x' buttons */}
//           <div className="flex flex-wrap gap-2 mt-3">
//             {propertyAmenities?.map((amenity, index) => (
//               <div
//                 key={index}
//                 className="flex items-center bg-gray-200 text-gray-700 px-3 py-1 rounded-full space-x-2"
//               >
//                 <span>{amenity}</span>
//                 <button
//                   type="button"
//                   onClick={() => handleRemoveAmenityProperty(amenity)}
//                   className="text-gray-500 hover:text-red-600"
//                 >
//                   &times;
//                 </button>
//               </div>
//             ))}
//           </div>
//         </div>
//         <div>
//           {/* cancellation_policy */}

//           <div>
//             <label htmlFor="cancellation_policy">Cancellation Policy:</label>
//             <textarea
//               id="cancellation_policy"
//               name="cancellation_policy"
//               rows="5"
//               cols="50"
//               placeholder="Enter the cancellation policy for the hotel"
//               value={hotelData.cancellation_policy}
//               onChange={(e) =>
//                 setHotelData((prevData) => ({
//                   ...prevData,
//                   cancellation_policy: e.target.value,
//                 }))
//               }
//             />
//           </div>
//         </div>
//   <button
//     type="submit"
//     disabled={loading}
//     className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-10 rounded-lg w-1/2 text-xl disabled:opacity-50 disabled:cursor-not-allowed"
//   >
//     {loading ? "Submitting..." : "Submit"}
//   </button>

//       </form>
//     </div>
//   );
// };

// export default HotelForm;
//////////////////////////////////////////////
import Compressor from "compressorjs";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  Button,
  FormControlLabel,
  FormControl,
  InputLabel,
  IconButton,
  debounce,
} from "@mui/material";

const HotelForm = () => {
  const [hotelData, setHotelData] = useState({
    hotel_name: "",
    hotel_owner_full_name: "", // Hotel Owner Full Name
    hotel_owner_mobile_number: "",
    description: "",
    account_number: "", // Account Number
    account_holder_name: "", // Account Holder Name
    bank_name: "", // Bank Name
    ifsc_code: "", // IFSC Code
    pan_number: "", // PAN Number
    gst_number: "", // GST Number
    aadhar_card_number: "",
    address: "",
    location_point: "",
    city_name: "",
    state_name: "",
    country_name: "",
    phone: "",
    email: "",
    hotel_password: "",
    rating: "",
    room_count: "",
    price_range: "",
    check_in_time: "",
    check_out_time: "",
    is_couple_allowed: false,
    open_24_7: false,
    deluxe_room_count: "",
    deluxe_room_price: "",
    super_deluxe_room_count: "",
    super_deluxe_room_price: "",
    standard_room_count: "",
    standard_room_price: "",
    suite_room_count: "",
    suite_room_price: "",
    cancellation_policy: "",
    payment_mode: 0,
  });

  const [imageFiles, setImageFiles] = useState({
    deluxe_room: [],
    super_deluxe_room: [],
    standard_room: [],
    suite_room: [],
    cancel_check_photo: [],
    gst_photo: [],
    aadhar_card_photo: [],
    reception: [],
    balcony: [],
  });

  const [imagePreviews, setImagePreviews] = useState({
    deluxe_room: [],
    super_deluxe_room: [],
    standard_room: [],
    suite_room: [],
    reception: [],
    balcony: [],
    cancel_check_photo: [],
    gst_photo: [],
    aadhar_card_photo: [],
  });

  //
  const [deluxe_room_guest_count, setDeluxe_room_guest] = useState("");
  const [super_deluxe_room_guest_count, setSuper_deluxe_room_guest] =
    useState("");
  const [standard_room_guest_count, setStandard_room_guest] = useState("");
  const [suite_room_guest_count, setSuite_room_guest] = useState("");

  const [deluxe_room_description, setDeluxe_room_description] = useState("");
  const [super_deluxe_room_description, setSuper_Deluxe_room_description] =
    useState("");
  const [standard_room_description, setStandard_room_description] =
    useState("");
  const [suite_room_description, setSuite_room_description] = useState("");

  // State to handle password visibility toggle
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the visibility
  };
  const [errors, setErrors] = useState({
    account_number: "",
    account_holder_name: "",
    bank_name: "",
    ifsc_code: "",
    pan_number: "",
    gst_number: "",
    aadhar_card_number: "",
    hotel_owner_full_name: "",
    hotel_owner_mobile_number: "",
    location_point: "",
    price_range: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedPolicies, setSelectedPolicies] = useState([]);

  const [propertyAmenities, setPropertyAmenities] = useState([]); // Selected amenities for Property Amenities
  const [selectedAmenityProperty, setSelectedAmenityProperty] = useState(""); // Currently selected amenity

  // List of all available property amenities
  const availablePropertyAmenities = [
    "Air Conditioning",
    "Laundry",
    "Newspaper",
    "Parking",
    "Room Service",
    "Smoke Detector",
    "Swimming Pool",
    "Wifi",
    "Lounge",
    "Reception",
    "Bar",
    "Restaurant",
    "Luggage Assistance",
    "Wheelchair Accessibility",
    "Gym/Fitness Centre",
    "CCTV",
    "Fire Extinguishers",
    "First-aid Services",
    "Airport Transfers",
    "Basic Facilities",
    "Elevator/Lift",
    "Housekeeping",
    "Kitchen/Kitchenette",
    "LAN",
    "Power Backup",
    "Genset",
    "Refrigerator",
    "Umbrellas",
    "Washing Machine",
    "Laundromat",
    "EV Charging Station",
    "Driver's Accommodation",
    "General Services",
    "Bellboy Service",
    "Caretaker",
    "Concierge",
    "Multilingual Staff",
    "Luggage Storage",
    "Specially Abled Assistance",
    "Wake-up Call/Service",
    "Butler Services",
    "Doctor on Call",
    "Medical Centre",
    "Pool/Beach Towels",
    "Outdoor Activities and Sports",
    "Beach",
    "Bonfire",
    "Golf",
    "Kayaks",
    "Outdoor Sports (Cricket, Volleyball, Basketball, Tennis, Badminton)",
    "Swings",
    "Rock Climbing",
    "Archery",
    "Hiking",
    "Squash",
    "Snorkelling",
    "Telescope",
    "Water Sports",
    "Water Park",
    "Diving",
    "Canoeing",
    "Skiing",
    "Jungle Safari",
    "Cycling",
    "Common Areas",
    "Balcony/Terrace",
    "Fireplace",
    "Indoor Seating Area",
    "Lawn",
    "Library",
    "Sun Deck",
    "Verandah",
    "Jacuzzi",
    "Prayer Room",
    "Living Room",
    "Outdoor Furniture",
    "Picnic Area",
    "Game Room",
    "Sitout Area",
    "Bonfire Pit",
    "Food and Drink",
    "Barbecue",
    "Café",
    "Coffee Shop",
    "Dining Area",
    "Kid's Menu",
    "Bakery",
    "Breakfast",
    "Food Options Available",
    "Business Center and Conferences",
    "Banquet",
    "Business Center",
    "Conference Room",
    "Photocopying",
    "Fax Service",
    "Printer",
  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Toggle dropdown visibility

  const handlePolicySelection = (event) => {
    const value = event.target.value;
    setSelectedPolicies((prev) => [...new Set(value)]); // Prevent duplicates using Set
  };

  // Handle Policy Removal
  const handleRemovePolicy = (policy) => {
    setSelectedPolicies((prev) => prev.filter((item) => item !== policy));
  };

  // Handle Amenity Selection
  const handleAmenitySelection = (event) => {
    const value = event.target.value;
    setPropertyAmenities((prev) => [...new Set(value)]); // Prevent duplicates using Set
  };

  // Handle Amenity Removal
  const handleRemoveAmenityProperty = (amenity) => {
    setPropertyAmenities((prev) => prev.filter((item) => item !== amenity));
  };
  // State for room amenities
  const [deluxeAmenities, setDeluxeAmenities] = useState([]);
  const [superDeluxeAmenities, setSuperDeluxeAmenities] = useState([]);
  const [standardAmenities, setStandardAmenities] = useState([]);
  const [suiteAmenities, setSuiteAmenities] = useState([]);

  // List of available amenities
  const availableAmenities = [
    "Hairdryer",
    "Hot & Cold Water",
    "Toiletries",
    "TV",
    "LED",
    "Air Conditioning",
    "Iron/Ironing Board",
    "Mineral Water",
    "Kettle",
    "Closet",
    "Mini Bar",
    "Telephone",
    "Local Calls",
    "Work Desk",
    "Safe",
    "Private Bathroom",
    "Chair",
    "Popular with Guests",
    "Interconnected Room",
    "Heater",
    "Housekeeping",
    "In-Room Dining",
    "Laundry Service",
    "Room Service",
    "Smoking Room",
    "Study Room",
    "Wifi",
    "Air Purifier",
    "Bathroom Phone",
    "Bathtub",
    "Bubble Bath",
    "Dental Kit",
    "Geyser/Water Heater",
    "Slippers",
    "Shower Cap",
    "Hammam",
    "Bathrobes",
    "Western Toilet Seat",
    "Shower Cubicle",
    "Weighing Scale",
    "Shaving Mirror",
    "Sewing Kit",
    "Bidet",
    "Toilet with Grab Rails",
    "Ensuite Bathroom/Common Bath",
    "Jetspray",
    "Blackout Curtains",
    "Center Table",
    "Charging Points",
    "Couch",
    "Dining Table",
    "Fireplace",
    "Mini Fridge",
    "Sofa",
    "Pillow Menu",
    "Hypoallergenic Bedding",
    "Living Area",
    "Dining Area",
    "Seating Area",
    "Fireplace Guards",
    "Coffee Machine",
    "Jacuzzi",
    "Hot Water Bag",
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setHotelData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  /////old code
  // const handleFileChange = (category, files) => {
  //   const selectedFiles = Array.from(files);
  //   const imageErrors = {};
  //   const updatedPreviews = [...imagePreviews[category]];

  //   selectedFiles.forEach((file) => {
  //     if (!file.type.startsWith("image/")) {
  //       imageErrors[category] = "Only image files are allowed.";
  //     }
  //     if (file.size > 5 * 1024 * 1024) {
  //       imageErrors[category] = "File size should not exceed 5 MB.";
  //     }
  //     const previewURL = URL.createObjectURL(file);
  //     updatedPreviews.push(previewURL);
  //   });

  //   setImageFiles({
  //     ...imageFiles,
  //     [category]: selectedFiles,
  //   });

  //   setImagePreviews({
  //     ...imagePreviews,
  //     [category]: updatedPreviews,
  //   });

  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     ...imageErrors,
  //   }));
  // };

  // Handling file change with compression
  const handleFileChange = (category, files) => {
    if (!files) {
      console.error("Files are undefined");
      return;
    }

    const selectedFiles = Array.from(files);
    const updatedCompressedFiles = [...(imageFiles[category] || [])];
    const updatedPreviews = [...(imagePreviews[category] || [])];

    selectedFiles.forEach((file) => {
      if (!file.type.startsWith("image/")) {
        console.error("Only image files are allowed.");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        console.error("File size should not exceed 5 MB.");
        return;
      }

      new Compressor(file, {
        quality: 0.9,
        maxWidth: 800,
        maxHeight: 800,
        success(compressedImage) {
          // Use the original file name and append "_compressed" for clarity
          const originalName = file.name;
          const newFileName = `${
            originalName.split(".")[0]
          }_compressed.${originalName.split(".").pop()}`;

          // Create a new File object with the original name
          const finalCompressedFile = new File([compressedImage], newFileName, {
            type: compressedImage.type,
            name: compressedImage.name,
            lastModified: Date.now(),
          });

          // Add compressed image to the array
          updatedCompressedFiles.push(finalCompressedFile);

          // Use compressed image directly for preview
          updatedPreviews.push(URL.createObjectURL(finalCompressedFile));

          // Update state with compressed images and previews
          setImageFiles((prevFiles) => ({
            ...prevFiles,
            [category]: updatedCompressedFiles,
          }));
          console.log(imageFiles);

          setImagePreviews((prevPreviews) => ({
            ...prevPreviews,
            [category]: updatedPreviews,
          }));

          // Log for debugging
          console.log("Compressed Image with New Name:", finalCompressedFile);
          console.log("Updated Image Files:", updatedCompressedFiles);
          console.log("Updated Previews:", updatedPreviews);
        },
        error(err) {
          console.error("Error during image compression:", err);
        },
      });
    });
  };

  const handleRemoveImage = (category, index) => {
    if (!imageFiles[category] || !Array.isArray(imageFiles[category])) {
      console.error(`No files found for category: ${category}`);
      return;
    }

    // Safely copy and modify the files array
    const updatedFiles = [...imageFiles[category]];
    updatedFiles.splice(index, 1); // Remove the file at the given index

    // Update state with the modified files array
    setImageFiles((prevFiles) => ({
      ...prevFiles,
      [category]: updatedFiles,
    }));

    // If previews are being handled, update them as well
    setImagePreviews((prevPreviews) => ({
      ...prevPreviews,
      [category]: updatedFiles.map((file) => URL.createObjectURL(file)), // Regenerate previews
    }));
  };

  const requiredFields = [
    "hotel_name",
    "account_number",
    "description",
    "address",
    "city_name",
    "state_name",
    "country_name",
    "phone",
    "email",
    "rating",
    "check_in_time",
    "check_out_time",
  ];

  const validateForm = () => {
    const newErrors = {};

    // Check required fields

    requiredFields.forEach((key) => {
      if (hotelData[key] === "" || hotelData[key] === null) {
        newErrors[key] = "This field is required";
      }
    });

    // Check if at least one room type is provided
    const roomTypes = [
      "deluxe_room",
      "super_deluxe_room",
      "standard_room",
      "suite_room",
    ];

    // Ensure at least one room type has data
    const hasRoomType = roomTypes.some((roomType) => {
      return (
        hotelData[`${roomType}_count`] > 0 && hotelData[`${roomType}_price`] > 0
      );
    });

    if (!hasRoomType) {
      newErrors.room_types = "At least one room type must be added.";
    }

    // Validate if images are uploaded for room types with data
    roomTypes.forEach((roomType) => {
      if (
        hotelData[`${roomType}_count`] > 0 &&
        imageFiles[roomType].length === 0
      ) {
        newErrors[
          roomType
        ] = `At least one image is required for ${roomType.replace(
          /_/g,
          " "
        )}.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    const formData = new FormData();

    // Append hotel data fields
    Object.keys(hotelData).forEach((key) => {
      formData.append(key, hotelData[key]);
    });

    // Append image files by category
    Object.keys(imageFiles).forEach((category) => {
      Array.from(imageFiles[category]).forEach((file) => {
        formData.append(`${category}[]`, file);
      });
    });

    // Log FormData content
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    // Append amenities and policies as JSON strings
    formData.append("deluxe_room_amenities", JSON.stringify(deluxeAmenities));
    formData.append(
      "super_deluxe_room_amenities",
      JSON.stringify(superDeluxeAmenities)
    );
    formData.append(
      "standard_room_amenities",
      JSON.stringify(standardAmenities)
    );
    formData.append("suite_room_amenities", JSON.stringify(suiteAmenities));
    formData.append("selected_policies", JSON.stringify(selectedPolicies));
    formData.append("property_amenities", JSON.stringify(propertyAmenities));

    // Append guest counts
    formData.append("deluxe_room_guest_count", deluxe_room_guest_count);
    formData.append(
      "super_deluxe_room_guest_count",
      super_deluxe_room_guest_count
    );
    formData.append("standard_room_guest_count", standard_room_guest_count);
    formData.append("suite_room_guest_count", suite_room_guest_count);

    // Append room descriptions
    formData.append("deluxe_room_description", deluxe_room_description);
    formData.append(
      "super_deluxe_room_description",
      super_deluxe_room_description
    );
    formData.append("standard_room_description", standard_room_description);
    formData.append("suite_room_description", suite_room_description);

    // Debugging: Display formData entries
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    console.log(formData);

    try {
      console.log(formData);

      const response = await fetch(
        "https://tabtrips.com/hotel_api/single_api.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        console.error("Response error status:", response.status);
        throw new Error(`Failed to add hotel: ${response.statusText}`);
      } else {
        alert("Data added successfully into the database");
      }

      const data = await response.json();
      console.log("Parsed API Response:", data);
    } catch (error) {
      console.error("Error in adding hotel:", error);
      alert("An error occurred while adding the hotel. Please try again.");
    } finally {
      setLoading(false);
      console.log("Hotel addition process complete.");
    }
  }; // Handle amenity selection for room types
  const handleSelectAmenity = (roomType, value) => {
    const updatedAmenities = value; // Get the selected values directly

    switch (roomType) {
      case "deluxe":
        setDeluxeAmenities(updatedAmenities);
        break;
      case "superDeluxe":
        setSuperDeluxeAmenities(updatedAmenities);
        break;
      case "standard":
        setStandardAmenities(updatedAmenities);
        break;
      case "suite":
        setSuiteAmenities(updatedAmenities);
        break;
      default:
        break;
    }
  };

  const handleRemoveAmenity = (roomType, amenity) => {
    switch (roomType) {
      case "deluxe":
        setDeluxeAmenities(deluxeAmenities.filter((item) => item !== amenity));
        break;
      case "superDeluxe":
        setSuperDeluxeAmenities(
          superDeluxeAmenities.filter((item) => item !== amenity)
        );
        break;
      case "standard":
        setStandardAmenities(
          standardAmenities.filter((item) => item !== amenity)
        );
        break;
      case "suite":
        setSuiteAmenities(suiteAmenities.filter((item) => item !== amenity));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".property-amenities-section")) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ padding: 4, maxWidth: 1200, margin: "0 auto" }}>
        {/* Form Header */}
        <Typography variant="h4" gutterBottom>
          Welcome to TabTrips: Add Your New Hotel
        </Typography>
        {/* Hotel Details Form */}
        <Typography variant="h4" gutterBottom>
          Hotel Form
        </Typography>
        <Grid container spacing={3}>
          {/* Hotel Name */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Hotel Name"
              name="hotel_name"
              value={hotelData.hotel_name}
              onChange={handleInputChange}
              error={!!errors.hotel_name}
              helperText={errors.hotel_name || ""}
              required
            />
          </Grid>

          {/* Hotel Owner Full Name */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Hotel Owner Full Name"
              name="hotel_owner_full_name"
              value={hotelData.hotel_owner_full_name}
              onChange={handleInputChange}
              error={!!errors.hotel_owner_full_name}
              helperText={errors.hotel_owner_full_name || ""}
              required
            />
          </Grid>

          {/* Hotel Owner Mobile Number */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Hotel Owner Mobile Number"
              name="hotel_owner_mobile_number"
              value={hotelData.hotel_owner_mobile_number}
              onChange={handleInputChange}
              error={!!errors.hotel_owner_mobile_number}
              helperText={errors.hotel_owner_mobile_number || ""}
              required
            />
          </Grid>

          {/* Account Number */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Account Number"
              name="account_number"
              value={hotelData.account_number}
              onChange={handleInputChange}
              error={!!errors.account_number}
              helperText={errors.account_number || ""}
              required
            />
          </Grid>

          {/* Account Holder Name */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Account Holder Name"
              name="account_holder_name"
              value={hotelData.account_holder_name}
              onChange={handleInputChange}
              error={!!errors.account_holder_name}
              helperText={errors.account_holder_name || ""}
              required
            />
          </Grid>

          {/* Bank Name */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Bank Name"
              name="bank_name"
              value={hotelData.bank_name}
              onChange={handleInputChange}
              error={!!errors.bank_name}
              helperText={errors.bank_name || ""}
              required
            />
          </Grid>

          {/* IFSC Code */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="IFSC Code"
              name="ifsc_code"
              value={hotelData.ifsc_code}
              onChange={handleInputChange}
              error={!!errors.ifsc_code}
              helperText={errors.ifsc_code || ""}
              required
            />
          </Grid>

          {/* PAN Number */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="PAN Number"
              name="pan_number"
              value={hotelData.pan_number}
              onChange={handleInputChange}
              error={!!errors.pan_number}
              helperText={errors.pan_number || ""}
              required
            />
          </Grid>

          {/* GST Number */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="GST Number"
              name="gst_number"
              value={hotelData.gst_number}
              onChange={handleInputChange}
              error={!!errors.gst_number}
              helperText={errors.gst_number || ""}
              required
            />
          </Grid>

          {/* Aadhar Card Number */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Aadhar Card Number"
              name="aadhar_card_number"
              value={hotelData.aadhar_card_number}
              onChange={handleInputChange}
              error={!!errors.aadhar_card_number}
              helperText={errors.aadhar_card_number || ""}
              required
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={1}
              label="Description"
              name="description"
              value={hotelData.description}
              onChange={handleInputChange}
              error={!!errors.description}
              helperText={errors.description || ""}
              required
            />
          </Grid>
        </Grid>

        {/* Address */}
        <Grid container spacing={3} mt={2}>
          {/* Address */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={hotelData.address}
              onChange={handleInputChange}
              error={!!errors.address}
              helperText={errors.address || ""}
              required
            />
          </Grid>

          {/* Location Point */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Location Point (latitude, longitude)"
              name="location_point"
              value={hotelData.location_point}
              onChange={handleInputChange}
              error={!!errors.location_point}
              helperText={errors.location_point || ""}
              required
            />
          </Grid>

          {/* Country */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Country"
              name="country_name"
              value={hotelData.country_name}
              onChange={handleInputChange}
              error={!!errors.country_name}
              helperText={errors.country_name || ""}
              required
            />
          </Grid>

          {/* State */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="State"
              name="state_name"
              value={hotelData.state_name}
              onChange={handleInputChange}
              error={!!errors.state_name}
              helperText={errors.state_name || ""}
              required
            />
          </Grid>

          {/* City */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="City"
              name="city_name"
              value={hotelData.city_name}
              onChange={handleInputChange}
              error={!!errors.city_name}
              helperText={errors.city_name || ""}
              required
            />
          </Grid>

          {/* Phone */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={hotelData.phone}
              onChange={handleInputChange}
              error={!!errors.phone}
              helperText={errors.phone || ""}
              required
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={hotelData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email || ""}
              required
            />
          </Grid>

          {/* Password */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              name="hotel_password"
              value={hotelData.hotel_password}
              onChange={handleInputChange}
              error={!!errors.hotel_password}
              helperText={errors.hotel_password || ""}
              required
              InputProps={{
                endAdornment: (
                  <Button onClick={handleTogglePasswordVisibility}>
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                ),
              }}
            />
          </Grid>

          {/* Rating */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Rating (1-5)"
              name="rating"
              type="number"
              value={hotelData.rating}
              onChange={handleInputChange}
              error={!!errors.rating}
              helperText={errors.rating || ""}
              inputProps={{ min: 1, max: 5 }}
              required
            />
          </Grid>

          {/* Room Count */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Hotel Total Room Count"
              name="room_count"
              type="number"
              value={hotelData.room_count}
              onChange={handleInputChange}
              error={!!errors.room_count}
              helperText={errors.room_count || ""}
              inputProps={{ min: 1 }}
              required
            />
          </Grid>

          {/* Price Range */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Price Range"
              name="price_range"
              value={hotelData.price_range}
              onChange={handleInputChange}
              error={!!errors.price_range}
              helperText={errors.price_range || ""}
              required
            />
          </Grid>

          {/* Check-in Time */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Check-in Time"
              type="time"
              name="check_in_time"
              value={hotelData.check_in_time}
              onChange={handleInputChange}
              error={!!errors.check_in_time}
              helperText={errors.check_in_time || ""}
              required
            />
          </Grid>

          {/* Check-out Time */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Check-out Time"
              type="time"
              name="check_out_time"
              value={hotelData.check_out_time}
              onChange={handleInputChange}
              error={!!errors.check_out_time}
              helperText={errors.check_out_time || ""}
              required
            />
          </Grid>

          {/* Couples Allowed */}
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_couple_allowed"
                  checked={!!hotelData.is_couple_allowed} // Convert to boolean
                  onChange={handleInputChange}
                />
              }
              label="Couples Allowed"
            />
          </Grid>

          {/* Open 24/7 */}
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="open_24_7"
                  checked={!!hotelData.open_24_7}
                  onChange={handleInputChange}
                />
              }
              label="Open 24/7"
            />
          </Grid>

          {/* Payment Mode */}
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="payment_mode"
                  checked={!!hotelData.payment_mode} // Ensures the value is boolean
                  onChange={handleInputChange}
                />
              }
              label="Payment Mode: Online/Offline"
            />
          </Grid>
        </Grid>

        <Box>
          {/* Room Types */}
          <Typography variant="h5" gutterBottom>
            Room Types:
          </Typography>

          {/* Deluxe Room */}
          <Box mb={4}>
            <Typography variant="h6">Deluxe Room:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Number of Deluxe Rooms"
                  type="number"
                  name="deluxe_room_count"
                  value={hotelData.deluxe_room_count}
                  onChange={handleInputChange}
                  error={!!errors.deluxe_room_count}
                  helperText={errors.deluxe_room_count || ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Price of Deluxe Room"
                  type="number"
                  name="deluxe_room_price"
                  value={hotelData.deluxe_room_price}
                  onChange={handleInputChange}
                  error={!!errors.deluxe_room_price}
                  helperText={errors.deluxe_room_price || ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Maximum Guests"
                  type="number"
                  name="deluxe_room_guest_count"
                  value={deluxe_room_guest_count}
                  onChange={(e) => setDeluxe_room_guest(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Deluxe Room Description"
                  type="text"
                  name="deluxe_room_description"
                  value={deluxe_room_description}
                  onChange={(e) => setDeluxe_room_description(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  multiple
                  value={deluxeAmenities}
                  onChange={(e) =>
                    handleSelectAmenity("deluxe", e.target.value)
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {availableAmenities.map((amenity, index) => (
                    <MenuItem key={index} value={amenity}>
                      <Checkbox
                        checked={deluxeAmenities.indexOf(amenity) > -1}
                      />
                      <ListItemText primary={amenity} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {deluxeAmenities.map((amenity, index) => (
                    <Chip
                      key={index}
                      label={amenity}
                      onDelete={() => handleRemoveAmenity("deluxe", amenity)}
                      color="primary"
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Super Deluxe Room */}
          <Box mb={4}>
            <Typography variant="h6">Super Deluxe Room:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Number of Super Deluxe Rooms"
                  type="number"
                  name="super_deluxe_room_count"
                  value={hotelData.super_deluxe_room_count}
                  onChange={handleInputChange}
                  error={!!errors.super_deluxe_room_count}
                  helperText={errors.super_deluxe_room_count || ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Price of Super Deluxe Room"
                  type="number"
                  name="super_deluxe_room_price"
                  value={hotelData.super_deluxe_room_price}
                  onChange={handleInputChange}
                  error={!!errors.super_deluxe_room_price}
                  helperText={errors.super_deluxe_room_price || ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Maximum Guests"
                  type="number"
                  name="super_deluxe_room_guest_count"
                  value={super_deluxe_room_guest_count}
                  onChange={(e) => setSuper_deluxe_room_guest(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Super Deluxe Room Description"
                  type="text"
                  name="super_deluxe_room_description"
                  value={super_deluxe_room_description}
                  onChange={(e) =>
                    setSuper_Deluxe_room_description(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  multiple
                  value={superDeluxeAmenities}
                  onChange={(e) =>
                    handleSelectAmenity("superDeluxe", e.target.value)
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {availableAmenities.map((amenity, index) => (
                    <MenuItem key={index} value={amenity}>
                      <Checkbox
                        checked={superDeluxeAmenities.indexOf(amenity) > -1}
                      />
                      <ListItemText primary={amenity} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {superDeluxeAmenities.map((amenity, index) => (
                    <Chip
                      key={index}
                      label={amenity}
                      onDelete={() =>
                        handleRemoveAmenity("superDeluxe", amenity)
                      }
                      color="primary"
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Standard Room */}
          <Box mb={4}>
            <Typography variant="h6">Standard Room:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Number of Standard Rooms"
                  type="number"
                  name="standard_room_count"
                  value={hotelData.standard_room_count}
                  onChange={handleInputChange}
                  error={!!errors.standard_room_count}
                  helperText={errors.standard_room_count || ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Price of Standard Room"
                  type="number"
                  name="standard_room_price"
                  value={hotelData.standard_room_price}
                  onChange={handleInputChange}
                  error={!!errors.standard_room_price}
                  helperText={errors.standard_room_price || ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Maximum Guests"
                  type="number"
                  name="standard_room_guest_count"
                  value={standard_room_guest_count}
                  onChange={(e) => setStandard_room_guest(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Standard Room Description"
                  type="text"
                  name="standard_room_description"
                  value={standard_room_description}
                  onChange={(e) => setStandard_room_description(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  multiple
                  value={standardAmenities}
                  onChange={(e) =>
                    handleSelectAmenity("standard", e.target.value)
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {availableAmenities.map((amenity, index) => (
                    <MenuItem key={index} value={amenity}>
                      <Checkbox
                        checked={standardAmenities.indexOf(amenity) > -1}
                      />
                      <ListItemText primary={amenity} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {standardAmenities.map((amenity, index) => (
                    <Chip
                      key={index}
                      label={amenity}
                      onDelete={() => handleRemoveAmenity("standard", amenity)}
                      color="primary"
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Suite Room */}
          <Box mb={4}>
            <Typography variant="h6">Suite Room:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Number of Suite Rooms"
                  type="number"
                  name="suite_room_count"
                  value={hotelData.suite_room_count}
                  onChange={handleInputChange}
                  error={!!errors.suite_room_count}
                  helperText={errors.suite_room_count || ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Price of Suite Room"
                  type="number"
                  name="suite_room_price"
                  value={hotelData.suite_room_price}
                  onChange={handleInputChange}
                  error={!!errors.suite_room_price}
                  helperText={errors.suite_room_price || ""}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Maximum Guests"
                  type="number"
                  name="suite_room_guest_count"
                  value={suite_room_guest_count}
                  onChange={(e) => setSuite_room_guest(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Suite Room Description"
                  type="text"
                  name="suite_room_description"
                  value={suite_room_description}
                  onChange={(e) => setSuite_room_description(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  multiple
                  value={suiteAmenities}
                  onChange={(e) => handleSelectAmenity("suite", e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {availableAmenities.map((amenity, index) => (
                    <MenuItem key={index} value={amenity}>
                      <Checkbox
                        checked={suiteAmenities.indexOf(amenity) > -1}
                      />
                      <ListItemText primary={amenity} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {suiteAmenities.map((amenity, index) => (
                    <Chip
                      key={index}
                      label={amenity}
                      onDelete={() => handleRemoveAmenity("suite", amenity)}
                      color="primary"
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Image Upload Section */}
        <Typography variant="h5" gutterBottom>
          Upload Hotel Images:
        </Typography>

        {[
          "deluxe_room",
          "super_deluxe_room",
          "standard_room",
          "suite_room",
          "reception",
          "balcony",
          "cancel_check_photo",
          "gst_photo",
          "aadhar_card_photo",
        ].map((category) => (
          <Box key={category} mb={3}>
            {/* Section Title */}
            <Typography variant="h6">
              {category.toLowerCase() === "balcony"
                ? "HOTEL IMAGES"
                : category.replace(/_/g, " ").toUpperCase()}
            </Typography>

            {/* File Upload */}
            <Button variant="contained" component="label">
              Upload Images
              <input
                hidden
                type="file"
                accept="image/*" // Restricts file selection to images only
                multiple
                onChange={(e) => handleFileChange(category, e.target.files)}
              />
            </Button>

            {/* Error Handling */}
            {errors[category] && (
              <Typography color="error" variant="body2">
                {errors[category]}
              </Typography>
            )}

            {/* Image Previews */}
            <Box
              sx={{
                overflowX: "auto", // Enables horizontal scrolling
                whiteSpace: "nowrap", // Prevents images from wrapping to the next line
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
            >
              <Box sx={{ display: "inline-flex", gap: "8px" }}>
                {imagePreviews[category]?.map((preview, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      width: "120px", // Reduced width for smaller previews
                      height: "120px", // Reduced height for smaller previews
                      flexShrink: 0, // Prevent shrinking in scrollable area
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                    }}
                  >
                    {/* Image Preview */}
                    <img
                      src={preview}
                      alt={`Preview ${category}${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Ensures the image fits within the box
                        borderRadius: "8px",
                      }}
                    />

                    {/* Remove Button */}
                    <IconButton
                      size="small"
                      sx={{
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        color: "#fff",
                      }}
                      onClick={() => handleRemoveImage(category, index)}
                    >
                      X
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ))}

        {/* Room Types Error */}
        {errors.room_types && (
          <Typography color="error" variant="body2">
            {errors.room_types}
          </Typography>
        )}

        <Box>
          {/* Property Policies */}
          <Typography variant="h5" gutterBottom>
            Property Policies
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Select Policies</InputLabel>
            <Select
              multiple
              value={selectedPolicies}
              onChange={handlePolicySelection}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((policy) => (
                    <Chip key={policy} label={policy} />
                  ))}
                </Box>
              )}
            >
              {[
                { label: "Unmarried Couples Allowed" },
                { label: "Guests Below 18 Allowed" },
                { label: "Male Groups Allowed" },
                { label: "Smoking Allowed" },
                { label: "Private Parties/Events Allowed" },
                {
                  label:
                    "Required IDs: Passport, Aadhar, Driving License, Government ID",
                },
                { label: "Local IDs Allowed" },
                { label: "Wheelchair Accessibility: Yes" },
                { label: "Pet Policy: Pets Allowed" },
                { label: "Current Pets on Property: None" },
                { label: "24-Hour Check-in Available" },
                {
                  label:
                    "Extra Beds for Adults: Available (Mattress ₹800 per adult)",
                },
                { label: "Extra Beds for Kids: Available" },
                { label: "Outside Visitors Allowed" },
              ].map((policy) => (
                <MenuItem key={policy.label} value={policy.label}>
                  <Checkbox checked={selectedPolicies.includes(policy.label)} />
                  <ListItemText primary={policy.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Selected Policies */}
          <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
            {selectedPolicies.map((policy, index) => (
              <Chip
                key={index}
                label={policy}
                onDelete={() => handleRemovePolicy(policy)}
                color="primary"
              />
            ))}
          </Box>

          {/* Property Amenities */}
          <Box mt={4}>
            <Typography variant="h5" gutterBottom>
              Property Amenities
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Select Amenities</InputLabel>
              <Select
                multiple
                value={propertyAmenities}
                onChange={handleAmenitySelection}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((amenity) => (
                      <Chip key={amenity} label={amenity} />
                    ))}
                  </Box>
                )}
              >
                {availablePropertyAmenities.map((amenity) => (
                  <MenuItem key={amenity} value={amenity}>
                    <Checkbox checked={propertyAmenities.includes(amenity)} />
                    <ListItemText primary={amenity} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Selected Amenities */}
            <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
              {propertyAmenities.map((amenity, index) => (
                <Chip
                  key={index}
                  label={amenity}
                  onDelete={() => handleRemoveAmenityProperty(amenity)}
                  color="primary"
                />
              ))}
            </Box>
          </Box>

          {/* Cancellation Policy */}
          <Box mt={4}>
            <Typography variant="h5" gutterBottom>
              Cancellation Policy
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={1}
              placeholder="Enter the cancellation policy for the hotel"
              value={hotelData.cancellation_policy}
              onChange={(e) =>
                setHotelData((prevData) => ({
                  ...prevData,
                  cancellation_policy: e.target.value,
                }))
              }
            />
          </Box>

          {/* Submit Button */}
          <Box mt={4}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default HotelForm;
