import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  TextField,
  Typography,
  Button,
  Container,
  Paper,
  Grid,
  Divider,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { orange, blue, grey } from "@mui/material/colors";

export default function AdminLogin() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ username: "", password: "" });
  const [bgColor, setBgColor] = useState(
    `linear-gradient(to right, ${orange[200]}, ${blue[200]})`
  );

  // Snackbar state
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");

  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setBgColor((prevColor) =>
        prevColor === `linear-gradient(to right, ${orange[200]}, ${blue[200]})`
          ? `linear-gradient(to right, ${blue[200]}, ${orange[200]})`
          : `linear-gradient(to right, ${orange[200]}, ${blue[200]})`
      );
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    // Avoid re-render if value hasn't changed
    setFormData((prevData) =>
      prevData[name] !== value ? { ...prevData, [name]: value } : prevData
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader
    try {
      const response = await fetch("https://tabtrips.com/hotel_api/admin_signin.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
console.log(result.message);

if (result.message && result.message.trim().toLowerCase() === "success") {
  sessionStorage.setItem("isLoggedIn", "true");
  
  setSnackMessage("Login successful!");
  setSnackSeverity("success");
  setSnackOpen(true);

  // Redirect to admin dashboard or home page
  navigate("/hotelform");
} else {
  setSnackMessage(result.message || "Invalid login credentials.");
  setSnackSeverity("error");
  setSnackOpen(true);
}
    } catch (error) {
      console.error("Error during admin login:", error);
      setSnackMessage("Unable to log in. Please try again later.");
      setSnackSeverity("error");
      setSnackOpen(true);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Box
        sx={{
          background: bgColor,
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "background 0.7s ease-in-out",
          py: 14,
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={12}
            sx={{
              p: 6,
              borderRadius: 5,
              backgroundImage: `linear-gradient(to right, ${orange[50]}, ${grey[50]})`,
              boxShadow: `0px 8px 15px rgba(0, 0, 0, 0.1)`,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: orange[600],
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
              }}
            >
              Admin Login
            </Typography>

            <Divider sx={{ my: 3 }} />

            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    variant="outlined"
                    required
                    InputLabelProps={{
                      style: { color: grey[700], fontWeight: "bold" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleChange}
                    variant="outlined"
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                            aria-label={
                              showPassword ? "Hide password" : "Show password"
                            }
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: orange[500],
                      color: "#fff",
                      fontWeight: "bold",
                      py: 1.5,
                      borderRadius: "25px",
                      "&:hover": {
                        backgroundColor: orange[700],
                      },
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Log In"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Box>

      {/* Snackbar Toasts */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackOpen(false)}
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
